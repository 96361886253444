import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'

import iconBanner from '../images/icon/RI/icon-banner.svg'
import resultados from '../images/icon/RI/icon-resultados.svg'
import debentures from '../images/icon/RI/icon-debentures.svg'
import framework from '../images/icon/RI/icon-framework.svg'
import pdfIcon from '../images/icon/RI/pdf.svg'
import emptyPdf from '../images/icon/RI/disablePDF.svg'
import close from '../images/icon/RI/close.svg'

import './styles/informacoes-financeiras.scss'

const Modal = ({ isOpen, onClose, pdfLink }) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleSubmit = () => {
    if (inputValue === '+Y37jn@m') {
      setInputValue('')
      window.open(pdfLink, '_blank')
    }
  }

  return (
    <div className='modal' style={{ display: isOpen ? 'block' : 'none' }}>
      <div className='modal-ri'>
        <div className='d-flex justify-content-end p-1'>
          <button onClick={onClose} className='btn-close-ri'><img src={close} /></button>
        </div>
        <div className='pt-md-4 px-md-5 pb-md-5 p-3'>
          <p>Por favor, digite a senha no campo abaixo para acessar o arquivo PDF.</p>
          <input type="password" value={inputValue} onChange={handleInputChange} placeholder='senha' />
          <button onClick={handleSubmit} className='mx-md-2 mx-0 btn-modal-ri px-2 mt-2 mt-md-0'>Confirmar</button>
        </div>
      </div>
    </div>
  )
}

const InformacoesFinanceiras = ({ data }) => {
  return (
    <>
      <Pagina pagina={data.pagina} />
      <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
      <CentralResultados arquivos={data.arquivos} />
      <Debentures arquivos={data.arquivos} />
      <Framework arquivos={data.arquivos} />
    </>
  )
}

const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)

  return (
    <div>
      {/* Desktop */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-flex d-none"
      >
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100 position-relative">
            <div className='vertical-line position-absolute'></div>
            <div className="col-2 p-0">
              <img src={iconBanner} className='w-90' />
            </div>
            <div className="col-5 p-0">
              <h1 className="text-white">Informações<br /> Financeiras</h1>
              <p className="text-white">Pesquise e baixe facilmente nossas Demonstrações de Resultados, Debêntures Emitidos e Framework de Finanças Verdes.</p>
            </div>
          </div>
        </div>
      </BackgroundImg>

      {/* Mobile */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-none d-flex"
      >
        <div className="container h-100 position-relative d-flex align-items-center">
          <div className='vertical-line position-absolute'></div>
          <div>
            <div className='d-flex align-items-center'>
              <img src={iconBanner} className='w-20 mr-3 ml-4' />
              <h1 className="text-white">Informações Financeiras</h1>
            </div>
            <div className='mt-3 ml-4'>
              <p className="text-white">Pesquise e baixe facilmente nossas Demonstrações de Resultados, Debêntures Emitidos e Framework de Finanças Verdes.</p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

const CentralResultados = ({ arquivos }) => {
  const [selectedYear, setSelectedYear] = useState('2023')

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const uniqueYears = [...new Set(arquivos.edges.map(edge => edge.node.data.split('/')[2]))]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentPDFLink, setCurrentPDFLink] = useState('')

  const openModal = (pdfLink) => {
    setCurrentPDFLink(pdfLink)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className='container h-100 p-5 position-relative'>
        <div className='d-flex justify-content-end mb-5 custom-select'>
          <select value={selectedYear} onChange={handleYearChange}>
            {uniqueYears.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className='doc-box position-relative'>
          <div className='d-flex'>
            <img src={resultados} className='position-absolute icon-position' />
            <h3 className='text-lwart-blue py-1'>Central de resultados</h3>
          </div>
          <div className='row d-md-flex d-none'>
            <div className='col-6 ml-4'>
              <p className='py-1 text-white'>.</p>
            </div>
            <div className='col py-1'>
              <h6 className='text-lwart-blue'>1T{selectedYear.substring(selectedYear.length - 2)}</h6>
            </div>
            <div className='col py-1'>
              <h6 className='text-lwart-blue'>2T{selectedYear.substring(selectedYear.length - 2)}</h6>
            </div>
            <div className='col py-1'>
              <h6 className='text-lwart-blue'>3T{selectedYear.substring(selectedYear.length - 2)}</h6>
            </div>
            <div className='col py-1'>
              <h6 className='text-lwart-blue'>4T{selectedYear.substring(selectedYear.length - 2)}</h6>
            </div>
          </div>
          <div>
            {arquivos.edges
              .filter(edge => edge.node.section === 'Central de Resultados' && edge.node.data.split('/')[2] === selectedYear)
              .length === 0 && (<p className='ml-4 mt-4 mb-3'>Nenhum arquivo encontrado.</p>)
            }
            {arquivos.edges
              .filter(edge => edge.node.section === 'Central de Resultados' && edge.node.data.split('/')[2] === selectedYear)
              .map(edge => (
                <div key={edge.node.nomeArquivo} className='row mb-2 mt-md-0 mt-4'>
                  <div className='col-md-6 col-12 ml-4 mb-4 mb-md-0 d-flex align-items-center'>
                    <p>{edge.node.nomeArquivo}</p>
                  </div>
                  {edge.node.nomeArquivo === 'Demonstrações Financeiras' ? (
                    <>
                      {[...Array(Math.max(4 - edge.node.arquivos.length, 0))].map((_, index) => (
                        <div key={`empty-${index}`} className='col'>
                          <img src={emptyPdf} width={30} />
                        </div>
                      ))}
                      {edge.node.arquivos.map(arquivo => (
                        <div key={arquivo.file.url} className='col'>
                          <a href="#!" onClick={() => openModal(arquivo.file.url)}>
                            <img src={pdfIcon} width={30} />
                          </a>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {edge.node.arquivos.map(arquivo => (
                        <div key={arquivo.file.url} className='col'>
                          <a href="#!" onClick={() => openModal(arquivo.file.url)}>
                            <img src={pdfIcon} width={30} />
                          </a>
                        </div>
                      ))}
                      {[...Array(Math.max(4 - edge.node.arquivos.length, 0))].map((_, index) => (
                        <div key={`empty-${index}`} className='col'>
                          <img src={emptyPdf} width={30} />
                        </div>
                      ))}
                    </>
                  )}
                  <hr className='w-95' />
                </div>
              ))}
          </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal} pdfLink={currentPDFLink} />
      </div>
    </>
  )
}

const Debentures = ({ arquivos }) => {
  const [selectedYear, setSelectedYear] = useState('2024')

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const uniqueYears = [...new Set(arquivos.edges.map(edge => edge.node.data.split('/')[2]))]

  return (
    <>
      <div className='container h-100 px-5 py-0 pt-5'>
        <div className='d-flex justify-content-end mb-5 custom-select'>
          <select value={selectedYear} onChange={handleYearChange}>
            {uniqueYears.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className='doc-box position-relative'>
          <div className='d-flex'>
            <img src={debentures} className='position-absolute icon-position' />
            <h3 className='text-lwart-blue py-1'>Debêntures</h3>
          </div>
          {arquivos.edges
            .filter(edge => edge.node.section === 'Debêntures' && edge.node.data.split('/')[2] === selectedYear)
            .length === 0 && (<p className='ml-4 mt-4 mb-3'>Nenhum arquivo encontrado.</p>)
          }
          {arquivos.edges
            .filter(edge => edge.node.section === 'Debêntures' && edge.node.data.split('/')[2] === selectedYear)
            .map(edge => (
              <div key={edge.node.nomeArquivo}>
                <div className='row mt-4 my-2'>
                  <div className='col-md-10 col-8 ml-4 d-md-flex align-items-center'>
                    <p className='mr-3'>{edge.node.data}</p>
                    <p>{edge.node.nomeArquivo}</p>
                  </div>
                  <div className='col'>
                    <a href={edge.node.arquivos[0].file.url} target="_blank" rel="noopener noreferrer">
                      <img src={pdfIcon} width={30} />
                    </a>
                  </div>
                </div>
                <hr className='w-95' />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

const Framework = ({ arquivos }) => {
  const [selectedYear, setSelectedYear] = useState('2024')

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const uniqueYears = [...new Set(arquivos.edges.map(edge => edge.node.data.split('/')[2]))]

  return (
    <>
      <div className='container h-100 p-5'>
        <div className='d-flex justify-content-end mb-5 custom-select'>
          <select value={selectedYear} onChange={handleYearChange}>
            {uniqueYears.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className='doc-box position-relative'>
          <div className='d-flex'>
            <img src={framework} className='position-absolute icon-position' />
            <h3 className='text-lwart-blue py-1'>Framework Finanças Verdes</h3>
          </div>
          {arquivos.edges
            .filter(edge => edge.node.section === 'Framework Finanças Verdes' && edge.node.data.split('/')[2] === selectedYear)
            .length === 0 && (<p className='ml-4 mt-4 mb-3'>Nenhum arquivo encontrado.</p>)
          }
          {arquivos.edges
            .filter(edge => edge.node.section === 'Framework Finanças Verdes' && edge.node.data.split('/')[2] === selectedYear)
            .map(edge => (
              <div key={edge.node.nomeArquivo}>
                <div className='row mt-4 my-2'>
                  <div className='col-md-10 col-8 ml-4 d-md-flex align-items-center'>
                    <p className='mr-3'>{edge.node.data}</p>
                    <p>{edge.node.nomeArquivo}</p>
                  </div>
                  <div className='col'>
                    <a href={edge.node.arquivos[0].file.url} target="_blank" rel="noopener noreferrer">
                      <img src={pdfIcon} width={30} />
                    </a>
                  </div>
                </div>
                <hr className='w-95' />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default InformacoesFinanceiras

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/informacoes-financeiras/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  arquivos: allContentfulRelacionamentoComInvestidor(filter: {node_locale: {eq: "pt-BR"}}) {
    edges {
      node {
        nomeArquivo
        section
        data
        arquivos {
          file {
            url
          }
        }
      }
    }
  }
  paralax: file(relativePath: {eq: "RI/banner.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
